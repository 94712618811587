<template>
  <v-expansion-panels class='mt-3' multiple>
    <v-expansion-panel v-for='(service, j) in services' :key='j' v-model='service.enabled'>
      <v-expansion-panel-header :hide-actions='!service.specifications'>
        <v-row no-gutters align='center'>
          <v-checkbox v-model='service.enabled' class='mr-3' hide-details style='margin-top: -5px' @click.stop />
          <v-img class='mr-3' :src='getImageUrl(service.image)' max-width='48' contain />
          <span class='mr-3'>{{ service.name }}</span>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-checkbox v-if='serviceRequiresLicense(service)' v-model='service.detached' class='mb-3 mr-3' hide-details style='margin-top: -3px' :label='$t("FULL_DETACHED")' />
        <v-checkbox v-if='service.name === "Infant"' v-model='service.allowSkipChildBiometry' class='mb-3 mr-3' hide-details style='margin-top: -3px' :label='$t("ALLOW_SKIP_CHILD_BIOMETRY")' @click='propertyChange("allow_skip_child_biometry", service.allowSkipChildBiometry, service)' />

        <h5>Contrato</h5>
        <v-col class='ma-0 pl-0 mb-2' style='max-width: 121px'>
          <v-text-field v-model='service.expirationDate' label='Data limite' type='date' underlined hide-details />
        </v-col>
        <v-col class='pt-0 px-0'>
          <v-row v-if='service.license' class='mt-3 wd-100' no-gutters>
            <v-btn v-if='isLicenseExpired(service.expirationDate)' color='primary' @click='$emit("renew-license", service)'> Renovar licença </v-btn>
            <v-btn v-else color='error' outlined @click='$emit("revoke-license", service)'> Cancelar licença </v-btn>
            <v-btn v-if='serviceRequiresLicense(service)' class='ml-5' color='primary' outlined @click='downloadLicense(service)'>
              <v-icon class='mr-3' small>mdi-download</v-icon>
              Baixar licença
            </v-btn>
          </v-row>
        </v-col>
        <div v-if='service.specifications'>
          <h5>Especificações</h5>
          <v-col v-for='(spec, index) in service.specifications' :key='index' class='ma-0 pa-2'>
            <v-checkbox v-if='spec.hasOwnProperty("enabled")' v-model='spec.enabled' :label='spec.name' color='primary' class='mr-3' hide-details style='margin-top: -5px' :disabled='!service.enabled' />
            <v-col v-else-if='spec.hasOwnProperty("amount")' class='ma-0 pa-0 ml-1' style='max-width: 121px'>
              <v-text-field v-model='spec.amount' :label='spec.name' type='number' underlined hide-details />
            </v-col>
          </v-col>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import Swal from 'sweetalert2';
  import { getImageUrl } from '../../util/image';

  export default {
    name: 'CustomersLicenses',
    props: {
      services: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      getImageUrl,
      isLicenseExpired: function (contractExpirationDate) {
        const today = new Date();
        const expDate = new Date(contractExpirationDate);
        return today.getTime() > expDate.getTime();
      },
      serviceRequiresLicense: function (service) {
        return ['Infant', 'Openbio Enroll'].includes(service.name);
      },
      downloadLicense: function (service) {
        this.$emit('download-license', { serviceName: service.name, serviceLicense: service.license });
      },
      propertyChange: function (code, value, spec) {
        if (code === 'allow_skip_child_biometry' && value) {
          Swal.fire({
            title: this.$t('ACTION_CONFIRMATION'),
            input: 'checkbox',
            inputPlaceholder: `
              ${this.$t('ACTION_CONFIRMATION_INPUT_CHECK')}
            `,
            confirmButtonText: `
              ${this.$t('CONTINUE')}
            `,
            html: `${this.$t('SKIP_CHILD_COLLECT_MESSAGE_BOX')}`,
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,
            cancelButtonText: this.$t('CANCEL'),
            inputValidator: (result) => !result && this.$t('ALLOW_TO_CONTINUE'),
          }).then((result) => {
            if (!result.isConfirmed) {
              spec.allowSkipChildBiometry = false;
            }
          });
        }
      },
    },
  };
</script>
